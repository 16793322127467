@font-face {
  font-family: 'GothamNarrowLight';
  src: url(../public/assets/fonts/GothamNarrow-Light.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GothamNarrowMedium';
  src: url(../public/assets/fonts/GothamNarrow-Medium.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GothamNarrowBook';
  src: url(../public/assets/fonts/GothamNarrow-Book.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

* {padding: 0; margin: 0;}

body {
  background-color: #401b52; 
  font-size: 19px;
}
.btnHover {
  transition: 0.2s ease-in-out;
}
.btnHover:hover {
  opacity: .5;
}

.titleFooter {
  font-size: 40px;
}
@media screen and (max-width: 768px) {
  .titleFooter {
    font-size: 35px;
  }
  .footer {flex-direction: column;}
  .dividerFooter {display: none !important;}
  #video {min-height: auto;}
  .hiddenMobile {display: none;}
  body {
    font-size: 16px;
  }
}